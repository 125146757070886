import styled from 'styled-components';
import { motion } from "framer-motion";

export const Wrapper = styled.div`
   width: 100%;
   .sm-font{
        font-size: 0.7rem;

        @media(max-width:540px){
            font-size: 0.6rem;
        }
    }
    .font-small{
        font-size: 1rem;

        @media(max-width:540px){
            font-size: 0.85rem;
        }
    }
`;
export const ContentBg = styled.div`
    background: var(--voskoolBagColor);
    width: 100%;
    height: 25vh;
    display: flex;
    justify-content: space-between;
    @keyframes anim_t{
        from{
            opacity: 0;
        }
        to{
            opacity: 1;
        }
    }
    
    
    .header{
        margin: auto 0;
        display: inline-block;
        padding-right: 5px;

        i{
            font-size: var(--fontMed);
            margin: 0 5px ;
            padding: 5px;
            border-radius: 50%;
            color: #ccc;
        }
        
        span.span{
            animation: anim_t 0.6s ease;
            font-size: var(--fontBig);
            color: #eee;
            font-weight: 600;
            text-transform: uppercase;
            letter-spacing: .6px;
        }
        span.under{
            animation: anim_t 0.6s ease;
            display: block;
            font-size: var(--fontSuperSmall);
            padding-top: 5px;
            padding-left: 20px;
            color: #aaa;
            font-weight: 500;
            font-style: italic;
        }
    }
    .header-img{
        width: 100px;
        padding-right: 20px;
        animation: anim_boy 1s ease;
        @keyframes anim_boy{
            from{
                opacity: 0;
                padding-right: 0px;
            }
            to{
                opacity: 1;
                padding-right: 20px;
            }
        }
        @media (max-width: 1280px){
            width: 150px;
            padding-right: 10px;
        }
        @media (max-width: 540px){
            width: 100px;
            padding-right: 5px;
        }
        img{
            width: 100%;
            height: 95%;
            z-index: 1;
            @media (max-width: 540px){
                width: 90%;
            }
        }
        
    }
`;

export const Content = styled(motion.div)`
    margin: 0 auto;
    background: #fff;
    box-shadow: 5px 3px 10px 4px rgba(0,0,0,0.1);
    transform: translateY(-35px);
    min-height: 75vh;
    width: 80%;
    border-radius: 15px;
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;

    .content{
        flex: 0 0 100%;
        max-width: 100%;

        .children{
            margin: 20px 0;
        }
        .header{
            display: flex;
            justify-content: space-between;
            
            width: 100%;

            .btna{
                margin-top:10px; 
                a{
                    padding: 10px 12px;
                    color: var(--voskoolBagColor);
                    border-radius: 5px;
                    font-weight: 600;
                    text-decoration: none;
                    @media(max-width: 400px){
                        font-size: var(--fontSuperSmall);
                    }
                    i{
                        padding-right: 5px;
                    }
                }
            }
        }
        .trans{         
            img{
                width: 125px;
                height: 100px;
                
                @media(max-width:540px){
                    width:80px;
                    height: 60px;
                }
            }
            img.default{
                width:100px;
                height:50px;
                @media(max-width:540px){
                    width:70px;
                    height: 40px;
                }
            }
            .img_span{
                font-weight: 700;
                font-size: 1rem;

                @media(max-width: 540px){
                    font-size: .8rem;
                }
            }
        }
    }

    .content-80{
        flex: 0 0 80%;
        max-width: 80%;
        @media(max-width: 1280px){
            flex: 0 0 70%;
            max-width: 70%;
        }
        @media(max-width: 780px){
            flex: 0 0 100%;
            max-width: 100%;
        }

        .children{
            margin: 20px 0;
        }
        .header{
            display: flex;
            justify-content: space-between;
            
            width: 100%;

            .btna{
                margin-top:10px; 
                a{
                    padding: 10px 12px;
                    color: var(--voskoolBagColor);
                    border-radius: 5px;
                    font-weight: 600;
                    text-decoration: none;
                    @media(max-width: 400px){
                        font-size: var(--fontSuperSmall);
                    }
                    i{
                        padding-right: 5px;
                    }
                }
            }
        }
        .trans{         
            img{
                width: 125px;
                height: 100px;
                
                @media(max-width:540px){
                    width:80px;
                    height: 60px;
                }
            }
            img.default{
                width:100px;
                height:50px;
                @media(max-width:540px){
                    width:70px;
                    height: 40px;
                }
            }
            .img_span{
                font-weight: 700;
                font-size: 1rem;

                @media(max-width: 540px){
                    font-size: .8rem;
                }
            }
        }
    }
    .content-20{
        flex: 0 0 20%;
        max-width: 20%;
        border-left: 4px solid #bbb;
        padding:0 5px;
        @media(max-width: 1280px){
            flex: 0 0 30%;
            max-width: 30%;
        }
        @media(max-width: 780px){
            flex: 0 0 100%;
            max-width: 100%;
            border-left: 0px;
            border-top: 4px solid #bbb;
            padding:5px;
        }
        .brb{
            border-bottom: 1px solid #ddd;
        }

        .wrap{
            display: flex;
            justify-content: space-between;
            
            
            .title-h{
                padding-top: 5px;
                margin-bottom: 20px;
                .heading{
                    font-size: var(--fontSmall);
                    font-weight: 600;
                    color: #333;
                }
            }
            .title{
                padding: 3px;
                
                .text{
                    font-size: var(--fontSuperSmall);
                    font-weight: 600;
                    color: #555;
                }
            }
            .icon{
                padding: 5px;
                .heading{
                    font-size: var(--fontMed);
                    color: #aaa;
                }
                .fa-check{
                    color: green;                                 
                }
                .fa-times{
                    color: red;   
                }
            }
        }
    }
`;