import React from "react";
import { Content } from "./style";

const Note = ({children}) => {
    return(
        <Content>
            <span className="head font-slightly-small">NOTE:</span><span className="font-slightly-small text">{children}</span>
        </Content>
    )
}

export default Note;