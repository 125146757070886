import React from "react";
import { Wrapper } from "./style";

const BackDrop = ({children, theme="", onClick}) => {
    return(
        <Wrapper className="z-9 app-shadow-color" style={theme === "white" ? {background:"#000000e1"} : {background:"#ffffffe11"}} onClick={onClick} initial={{opacity: 0}} animate={{opacity: 1}} exit={{opacity: 0}} //transition={{duration: 1}}
        >
            {children}
        </Wrapper>
    )
}

export default BackDrop;