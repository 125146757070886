export const formatNumber = (mon='0', type='en') => {
    const formatter = Intl.NumberFormat(type, {

    })
    const money = formatter.format(mon.toString().replace(/,|\./g, ""))
    if(money === "NaN" || money === '0'){
        return '';
    }
    return money;
}

export const convertToNumber = (mon="0") => {
    return mon.toString().replace(/,|\./g, "");
}

export const convertNumber = (mon='0', type='en') => {
    const formatter = Intl.NumberFormat(type, {
        
    })
    const money = formatter.format(mon);
    if(money === "NaN"){
        return 0;
    }
    return money;
}

export const seperatePoint = (mon="0", view_point=false) => {
    const mon_third = mon.length - 3;
    const mon_second = mon.length - 2;
    const point = mon.slice(mon_second, mon.length)
    if(view_point){
        return point;
    }
    return mon.slice(0, mon_third)
}