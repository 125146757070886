import React from "react";
import { Wrapper, Content } from "./style";
const Preloader = () => {
    return(
        <Wrapper>          
            <div className='preloader_container'>
                <Content>
                    <div className='loader--dot'></div>
                    <div className='loader--dot'></div>
                    <div className='loader--dot'></div>
                    <div className='loader--dot'></div>
                    <div className='loader--dot'></div>
                    <div className='loader--dot'></div>
                    <div className='loader--text'></div>
                </Content>
            </div>
        </Wrapper>
    )
}

export default Preloader;