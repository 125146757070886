import React from "react";
import { Wrapper, CContent } from "./style";
import Spinner from "components/spinner";

export const TelCountries = ({searched='', next, loading=false, onClickLoadMore, onChange, children}) => {
    
    return(
        <CContent>                 
            <input type="text" name="search" value={searched} onChange={onChange} placeholder="Search..." className="c" />
            {children}
            {loading && (<Spinner />)}
            {next && (<button type="button" className="btn-n" onClick={onClickLoadMore}>Load More <i className="fas fa-arrow-down"></i></button>)}
        </CContent>
    )
}
const TelInput = ({onClick, flag, closed, data, phone, phone_code,  cname, onChange, children}) => {

    return(
        <>
            <Wrapper>
                <div className="c30">
                    <div className="content" onClick={onClick}>
                        <div className="flag">
                            {flag && <img src={flag}  alt="flag" />}
                        </div>
                        <div>
                            <input className="inp-num" name="phone_code" value={phone_code ? phone_code : '+'} type="text" readOnly />
                        </div>
                        <div>
                            <i className="fas fa-angle-down"></i>
                        </div>
                    </div>
                    {!closed ?
                        children : (<div style={{display:'none'}}>{children}</div>)
                    }
                    
                </div>
                <div className="c70">
                    <input type="text" placeholder="Phone Number" name="phone" onChange={onChange} value={phone} className={`f-c ${cname} c`} disabled={phone_code ? false : true} />
                </div>
            </Wrapper>
        </>
    )
}


export default TelInput;