import styled from "styled-components";

export const Wrapper = styled.div`
    width: 100%;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    img{
        width: 18px;
        height: 15px;
        @media(max-width: 540px){
            width: 15px;
        }
    }
    .c30{
        flex: 0 0 30%;
        max-width: 30%;
        .content{
            display: flex;
            justify-content: space-between;
            border: 1px solid #888;
            padding: 2px;
            border-radius: 5px;
            border-bottom-right-radius: 0;
            border-top-right-radius: 0;
            img{
                @media(max-width: 440px){
                    display: none;
                }
                @media(max-width: 400px){
                    display: inline-block;
                }
            }
            .fa-angle-down{
                font-size: var(--fontSuperSmall);
            }
            .flag{
                margin-right: 6px;
                @media(max-width: 540px){
                    margin-right: 0px;
                }
                @media(max-width: 400px){
                    margin-right: 5px;
                }
                @media(max-width: 320px){
                    margin-right: 0px;
                }
            }
            .inp-num{
                width: 100%;
                border: none;
                outline: none;
                font-size: 0.7rem;
                color: #333;
                cursor: default;
                font-weight: 600;
            }
        }
    }
    .c70{
        flex: 0 0 70%;
        max-width: 70%;
        .c{
            border-bottom-left-radius: 0;
            border-top-left-radius: 0; 
        }
    }
`;

export const CContent = styled.div`
    position: absolute;
    z-index: 3;
    background: #fff;
    padding: 5px;
    width: 135px;
    max-height: 300px;
    border-radius: 3px;
    box-shadow: 0 0 3px 2px rgba(0,0,0,0.1);
    overflow-y: auto;
    @media(max-width: 440px){
        width: 105px;
    }
    .btn-n{
        padding: 6px;
        font-size: var(--fontSuperSmall);
        color: #111;
        background: var(--voskoolBagColor);
        transition: .4s ease;
        border: 1px solid var(--voskoolBagColor);
        border-radius: 7px;
        color: #eee;
        margin-top: 6px;
    }
    .c{
        width: 100%;
        background: #eee;
        outline: none;
        font-size: var(--fontSuperSmall);
        border: 1px solid #aaa;
        padding: 2px 5px;
        border-radius: 8px;
        margin-bottom: 7px;
    }
    .inner-content.selected{
        background: var(--voskoolBagColor);

        .text{
            color: #eee;
        }
        .text-small{
            color: #ccc;
        }
    }
    .inner-content{
        display: flex;
        justify-content: space-between;
        transition:.3s ease;
        border-bottom: 1px solid #ccc;
        margin-bottom: 4px;
        padding: 2px;
        cursor: default;
        :hover{
            border-bottom: 1px solid var(--voskoolBagColor);
            border-radius: 5px;
        }

        .text{
            font-size: 0.7rem;
            color: #222;
            font-weight: 600;
        }
        .text-small{
            font-size: 0.5rem;
            color: #111;
            font-weight: 600;
        }
    }
`;