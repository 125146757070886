import styled from "styled-components";

export const Wrapper = styled.div`
    margin-top: 50px;
    width: 100%;
    height: 135px;
    background: var(--unAssignedColor);
    border-radius: 6px;
    animation: anim_wrapper 1s ease;
    @keyframes anim_wrapper{
        from{
            opacity: 0;
        }
        to{
           opacity: 1;
        }
    }
    @media(max-width: 540px){
        height: 110px;
    }
    @media(max-width: 280px){
        height: 80px;
    }
`;
export const Content = styled.div`
    display: flex;
    -ms-display: flexbox;
    -webkit-display: flex;
    -moz-display: flex;
    justify-content: space-between;
    align-self: center;

    .title_cont{
        position: relative;
        padding: 7px 35px;

        h2{
            font-size: 2.5rem;
            color: #ddd;
            text-transform: capitalize;
            letter-spacing: 1px;
            animation: anim_head 2s ease;
            @keyframes anim_head{
                from{
                    opacity: 0;
                }
                to{
                    opacity: 1;
                }
            }
            @media(max-width: 780px){
                font-size: 2.3rem;
            }
            @media(max-width: 540px){
                font-size: 1.5rem;
            }
            @media(max-width: 280px){
                font-size: 1rem;
            }
        }
        h2::after{
            content: "";
            position: absolute;
            bottom: 22%;
            left: 39%;
            width: 35%;
            margin-left: 5px;
            border-bottom: 9px solid var(--voskoolBagColor);
            border-radius: 6px;
            animation: anim_head_after 1s ease;
            @keyframes anim_head_after{
                from{
                    transform: translateX(-40px);
                }
                to{
                    transform: translateX(0px);
                }
            }
            @media(max-width: 540px){
                border-bottom: 6px solid var(--voskoolBagColor);
            }
            @media(max-width: 280px){
                border-bottom: 4px solid var(--voskoolBagColor);
                left: 44%;
            }
        }
    }
    .img_cont{
        padding: 7px 35px;
        margin: 1px;
        width: 45%;
        position: relative;

        img{
            position: absolute;
            width: 15px;
            height: 15px;
            z-index: 1;
            animation: anim_icons infinite 2s ease;
            @keyframes anim_icons{
                from{
                    transform: rotate(360deg);
                }
                to{
                    transform: rotate(0deg);
                }
            }
            @media(max-width: 780px){
                width: 13px;
                height: 13px;
            }
            @media(max-width: 540px){
                width: 11px;
                height: 11px;
            }
            @media(max-width: 280px){
                width: 6px;
                height: 6px;
            }
        }
        .icon-1{   
            top:50%;
            animation-delay: 1s;
        }
        .icon-2{
            top:20%;
            left: 0%;
            animation-delay: 2s;
        }
        .icon-3{
            top:30%;
            right: 15%;
            animation-delay: 2.5s;
        }
        .icon-4{
            bottom:30%;
            left: 30%;
            animation-delay: 3.5s;
        }
        .icon-5{
            bottom:10%;
            right: 30%;
            animation-delay: 4s;
        }
        .icon-6{
            bottom:20%;
            right: 10%;
            animation-delay: 6s;
        }
        .icon-7{
            top:20%;
            left: 40%;
            animation-delay: 7s;
        }
        .icon-8{
            bottom:24%;
            left: 50%;
            animation-delay: 8s;
        }
        .icon-9{
            top:24%;
            left: 50%;
            animation-delay: 9s;
        }
        .icon-10{
            top:24%;
            left: 30%;
            animation-delay: 10s;
        }
        .icon-11{
            top:80%;
            left: 20%;
            animation-delay: 11s;
        }
        .icon-12{
            top:39%;
            right: 25%;
            animation-delay: 12s;
        }
        .icon-13{
            top:44%;
            right: 50%;
            animation-delay: 13s;
        }
        .icon-14{
            top:10%;
            right: 5%;
            animation-delay: 14s;
        }
        
    }
    
`;
